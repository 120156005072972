<template>
    <div>
        <b-card>
            <b-row v-if="result && result.user">
                <b-col cols="4" class="mb-25">Nama</b-col>
                <b-col cols="8" class="mb-25"><span class="ml-1">: {{ result.user.name }}</span></b-col>

                <b-col cols="4" class="mb-25">Email</b-col>
                <b-col cols="8" class="mb-25"><span class="ml-1">: {{ result.user.email }}</span></b-col>

                <b-col cols="4" class="mb-25">No Handphone</b-col>
                <b-col cols="8" class="mb-25"><span class="ml-1">: {{ result.user.phone_number }}</span></b-col>

                <b-col cols="4" class="mb-25">Cicilan per bulan</b-col>
                <b-col cols="8" class="mb-25"><span class="ml-1">: {{ formattedAmount(result.monthly_installment) }}</span></b-col>

                <b-col cols="4" class="mb-25">Total pinjaman</b-col>
                <b-col cols="8" class="mb-25"><span class="ml-1">: {{ formattedAmount(result.total) }}</span></b-col>

                <b-col cols="4" class="mb-25">Status</b-col>
                <b-col cols="8" class="text-capitalize"><span class="ml-1">: 
                    <b-badge :variant="'light-' + status_badge">
                        {{ result.status }}
                    </b-badge>
                </span></b-col>
                
            </b-row>
            <hr class="mt-2"/>
            <b-row v-for="(item, index) in result.items" :key="index" class="mt-2">
                <b-col cols="12 text-capitalize">
                    <h4> 
                        {{  setItemTitle(item.type) }}
                     </h4>
                </b-col>
                <b-col cols="12" class="border-bottom">
                    <b-table :items="item.details" :fields="fields" head-variant="light">
                        <template v-slot:cell(type)="{ item }">
                            {{  setItemTypeName(item.type) }}
                        </template>
                        <template v-slot:cell(name)="{ item }">
                            {{  setFormattedItemName(item) }}
                        </template>
                        <template v-slot:cell(amount)="{ item }">
                            {{  formattedAmount(item.amount) }}
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="3" class="ml-auto mt-2">
                    <h4> {{  setItemTotalLabel(item.type) }} : <span class="ml-2">{{ formattedAmount(item.total) }}</span></h4>
                </b-col>
                <b-col cols="12">
                    <hr class="mt-2" v-if="index !== result.items.length - 1"/>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BTable,
        BBadge,
    } from "bootstrap-vue";
    export default {
        components: {
            BCard,
            BCol,
            BRow,
            BTable,
            BBadge,
        },
        data(){
            return{
                result: [],
                user: [],
                fields: [
                    {key: 'type', thClass: 'bg-white'},
                    {key: 'name', thClass: 'bg-white'},
                    {key: 'amount', thClass: 'bg-white'}
                ],
            }
        },
        computed: {
            status_badge () {
                const statuses = {
                    'approved': 'success',
                    'on_process': 'warning',
                    'rejected': 'danger'  
                }
                return statuses[this.result.status];
            },

        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                this.$http.get(`/api/v1/admin/bank-loan/${this.$route.params.uuid}`,)
                  .then((response) => {
                    this.result = response.data.data;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            },
            setItemTitle(type) {
                const types = {
                    'first_month_net_profit': 'Laba Rugi Bulan ke 1',
                    'second_month_net_profit': 'Laba Rugi Bulan ke 2',
                    'third_month_net_profit': 'Laba Rugi Bulan ke 3',
                    'fund_allocation': 'Alokasi Dana'
                }

                return types[type];
            },
            setItemTypeName(type) {
                const types = {
                    'income': 'Pemasukan',
                    'outcome': 'Pengeluaran',
                    'fund_allocation': 'Alokasi Dana'
                }

                return types[type];
            },
            setFormattedItemName(item) {
                const name = item.custom_name !== null && item.slug == 'other' ? item.name + ' - ' + item.custom_name : item.name

                return name;
            },
            setItemTotalLabel(type) {
                const label = type.includes('profit') ? 'Total Profit' : 'Total'

                return label;
            },
            formattedAmount(amount) {
                return String(amount).replace(/[^\d.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
        }
    }
</script>